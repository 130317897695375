<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item>
        <el-breadcrumb-item>库存审核</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button type="success" style="height: 36px" @click="reload">
        <i style="font-size: 18px" class="el-icon-refresh-right"></i>
      </el-button>
    </div>
    <div class="tableInput">
      <span style="font-size: 12px">创建日期：</span>
      <el-date-picker type="date" v-model="dateTime.date1" style="width: 12%"></el-date-picker>
      <span style="margin: 0 5px">-</span>
      <el-date-picker type="date" v-model="dateTime.date2" style="width: 12%"></el-date-picker>
      <span style="font-size: 12px; margin-left: 10px">订单状态：</span>
      <el-select v-model="status" clearable style="width: 8% !important; margin: 0 5px">
        <el-option v-for="item in statuses" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
      <el-input v-model="QueryContent" placeholder="输入要查询的站号，手机号" style="width: 20%; margin-right: 5px"></el-input>
      <el-button type="success"><i class="el-icon-search"></i>查询</el-button>
    </div>
    <div class="table">
      <div style="padding: 10px 0">
        <el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark"
          style="width: 100%; border: 1px solid #e5e5e5" @selection-change="handleSelectionChange">
          <el-table-column type="selection" align="center"> </el-table-column>
          <el-table-column prop="creatDate" sortable label="创建日期" align="center">
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center">
          </el-table-column>
          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.status }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <i class="el-icon-edit" style="cursor: pointer" @click="handleEdit(scope.$index, scope.row)"></i>
              <span style="margin: 0 10px"></span>
              <i class="el-icon-delete" style="cursor: pointer" @click="handleDelete(scope.$index, scope.row)"></i>
            </template>
          </el-table-column>
        </el-table>
        <div class="tableBottom">
          <span v-if="isResult" style="">显示第&nbsp;1&nbsp;至&nbsp;{{
            tableFinshNum
          }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span>
          <span v-if="!isResult" style="">显示&nbsp;{{ tableFinshNum }}&nbsp;项结果，共&nbsp;{{
            tableFinshNum
          }}&nbsp;项</span>
          <div style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            ">
            <el-button>首页</el-button>
            <el-button>上页</el-button>
            <el-pagination background layout=" pager,slot" :total="1"></el-pagination>
            <el-button>下页</el-button>
            <el-button>末页</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      dateTime: { date1: "", date2: "" },
      status: "订单状态",
      type: "类型",
      statuses: ["未确认", "已确认"],
      QueryContent: "",

      statusRadio: "全部",

      tableData: [
        {
          id: 1,
          creatDate: "",
          remark: "",
          status: "已确认",
        },
        {
          id: 2,
          creatDate: "",
          remark: "",
          status: "未确认",
        },
        {
          id: 3,
          creatDate: "",
          remark: "",
          status: "已确认",
        },
      ],
      multipleSelection: [],
      isResult: false,
      tableFinshNum: 0,
    };
  },
  mounted() {
    if (this.tableData.length == 0) {
      this.isResult = false;
    } else {
      this.isResult = true;
      this.tableFinshNum = this.tableData.length;
    }
  },
  computed: {},
  methods: {
    reload() {
      location.reload();
    },
    handleEdit() { },
    handleDelete() { },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>
<style lang="less" scoped>
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableInput {
  height: 31px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
}

.table {
  padding: 20px;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}
</style>
